import React from "react";
import queryString from "query-string";
import SEO from "@src/components/SEO";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import window from "global/window";
import { incrementMetric } from "@src/utils/logClient";

const Home = () => {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { s, t } = parsedLocation;

  useRedirectHandler(() => {
    if (s && t) {
      return `https://student.classdojo.com/#/directLogin?token=${t}&studentId=${s}`;
    } else {
      incrementMetric("external_site.universal_link.fallback", { component: "Home" });
      return `https://student.classdojo.com/#/story`;
    }
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default Home;
